export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは2月より%d点上がりました。年度末の慌ただしい時期に素晴らしいです！<br>
      4月のテーマは、<b>❝新年度はじめ！健康はじめ！❞</b>。環境が変化することが多いこの時期、新生活を元気に乗る切るヒントとなるコンテンツをお届けします。4月18日から<b>Walk チーム対抗戦のエントリー</b>が始まります。新しい仲間と交流するきっかけにも！
      `,
      down: `生活習慣スコアは2月より%d点下がりました。分類別評価でどの項目に変化があったのか見てみましょう。<br>
      4月のテーマは、<b>❝新年度はじめ！健康はじめ！❞</b>。環境が変化することが多いこの時期、新生活を元気に乗る切るヒントとなるコンテンツをお届けします。4月18日から<b>Walk チーム対抗戦のエントリー</b>が始まります。新しい仲間と交流するきっかけにも！
      `,
      equal100: `生活習慣スコアは2月に引き続き%満点%です。年度末の慌ただしい時期に大変素晴らしいです！<br>
      4月のテーマは、<b>❝新年度はじめ！健康はじめ！❞</b>。環境が変化することが多いこの時期、新生活を元気に乗る切るヒントとなるコンテンツをお届けします。4月18日から<b>Walk チーム対抗戦のエントリー</b>が始まります。新しい仲間と交流するきっかけにも！
      `,
      equal: `生活習慣スコアは2月と同じ点数です。<br>
      4月のテーマは、<b>❝新年度はじめ！健康はじめ！❞</b>。環境が変化することが多いこの時期、新生活を元気に乗る切るヒントとなるコンテンツをお届けします。4月18日から<b>Walk チーム対抗戦のエントリー</b>が始まります。新しい仲間と交流するきっかけにも！
      `,
    },
    steps: {
      up: `歩数スコアは2月より%d点上がりました。<br>
      その調子です。こまめなストレッチと水分補給も忘れずに。`,
      down: `歩数スコアは2月より%d点下がりました。<br>
      そろそろWalk チーム対抗戦に向けて、身体を動かして準備していきませんか？`,
      equal: `歩数スコアは2月と同じです。<br>
      そろそろWalk チーム対抗戦に向けて、身体を動かして準備していきませんか？`,
    },
    exercise: {
      up: `運動のスコアは2月より%d点上がりました。<br>
      階段を積極的に利用したり、早歩きしたりなど、ちょっとした工夫や心がけで日常生活の中で活動量を増やすことができますよ。
      `,
      down: `運動のスコアは2月より%d点下がりました。<br>
      階段を積極的に利用したり、早歩きしたりなど、ちょっとした工夫や心がけで日常生活の中で活動量を増やすことができますよ。
      `,
      equal: `運動のスコアは2月と同じ点数です。<br>
      階段を積極的に利用したり、早歩きしたりなど、ちょっとした工夫や心がけで日常生活の中で活動量を増やすことができますよ。
      `,
    },
    meal: {
      up: `食事のスコアは2月より%d点上がりました。<br>
      朝食をしっかり食べると、集中力や記憶力のUPにつながります。「糖質」と「タンパク質」を摂ることを心がけてください。
      `,
      down: `食事のスコアは2月より%d点下がりました。<br>
      朝食をしっかり食べると、集中力や記憶力のUPにつながります。「糖質」と「タンパク質」を摂ることを心がけてください。
      `,
      equal: `食事のスコアは1月と同じ点数です。<br>
      朝食をしっかり食べると、集中力や記憶力のUPにつながります。「糖質」と「タンパク質」を摂ることを心がけてください。
      `,
    },
    drinking: {
      up: `飲酒のスコアは2月より%d点上がりました。<br>
      アルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。こまめに水を飲むことを意識しましょう。
      `,
      down: `飲酒のスコアは2月より%d点下がりました。<br>
      アルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。こまめに水を飲むことを意識しましょう。
      `,
      equal: `飲酒のスコアは2月と同じ点数です。<br>
      アルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。こまめに水を飲むことを意識しましょう。
      `,
    },
    sleep: {
      up: `睡眠のスコアは2月より%d点上がりました。<br>
      春は1年の中で特に寒暖差が激しく、睡眠の質が下がりやすい時期です。
      朝起きたらすぐに太陽の光を浴びて、体内時計をリセット！
      `,
      down: `睡眠のスコアは2月より%d点下がりました。<br>
      春は1年の中で特に寒暖差が激しく、睡眠の質が下がりやすい時期です。
      朝起きたらすぐに太陽の光を浴びて、体内時計をリセット！
      `,
      equal: `睡眠のスコアは2月と同じ点数です。<br>
      春は1年の中で特に寒暖差が激しく、睡眠の質が下がりやすい時期です。
      朝起きたらすぐに太陽の光を浴びて、体内時計をリセット！
      `,
    },
    stress: {
      up: `ストレスのスコアは2月より%d点上がりました。<br>
      新年度は異動など、環境の変化が多くありますね。
      初対面の方と上手く話せない…
      とお悩みの方にピッタリの動画が<a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">こちら！</a>
      `,
      down: `ストレスのスコアは2月より%d点下がりました。<br>
      新年度は異動など、環境の変化が多くありますね。
      初対面の方と上手く話せない…
      とお悩みの方にピッタリの動画が<a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">こちら！</a>
      `,
      equal: `ストレスのスコアは2月と同じ点数です。<br>
      新年度は異動など、環境の変化が多くありますね。
      初対面の方と上手く話せない…
      とお悩みの方にピッタリの動画が<a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">こちら！</a>
      `,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up by %d pts since February. This is an outstanding achievement for the hectic end of the fiscal year!<br>
      April theme is "<b>New Fiscal Year, New Healthy Start!</b>" This is a time of year when many people's environments change, so we're going to give you content that'll give you hints on how to launch into your new life in good spirits. <b>Entering for the Team Walk Competition</b> will open on April 18. It'll also be a good opportunity to do things with new friends!
      `,
      down: `Your lifestyle score has gone down by %d pts since February. Use the categorized ratings to see what items have changed.<br>
      April theme is "<b>New Fiscal Year, New Healthy Start!</b>" This is a time of year when many people's environments change, so we're going to give you content that'll give you hints on how to launch into your new life in good spirits. <b>Entering for the Team Walk Competition</b> will open on April 18. It'll also be a good opportunity to do things with new friends!
      `,
      equal100: `Your lifestyle score is perfect, as it was in February.This is an outstanding achievement for the hectic end of the fiscal year!
      April theme is "<b>New Fiscal Year, New Healthy Start!</b>" This is a time of year when many people's environments change, so we're going to give you content that'll give you hints on how to launch into your new life in good spirits. <b>Entering for the Team Walk Competition</b> will open on April 18. It'll also be a good opportunity to do things with new friends!
      `,
      equal: `Your lifestyle score is the same as February.<br>
      April theme is "<b>New Fiscal Year, New Healthy Start!</b>" This is a time of year when many people's environments change, so we're going to give you content that'll give you hints on how to launch into your new life in good spirits. <b>Entering for the Team Walk Competition</b> will open on April 18. It'll also be a good opportunity to do things with new friends!
      `,
    },
    steps: {
      up: `Your steps score has gone up %d pts since February.<br>
      Keep it up, and don't forget to stretch regularly and stay hydrated.
      `,
      down: `Your steps score has gone down %d pts since February. <br>
      It's time to get moving and get ready for the Walk Team Competition.
      `,
      equal: `Your steps score is the same as February. <br>
      It's time to get moving and get ready for the Walk Team Competition.
      `,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since February.<br>
      You can increase your activity level in your daily life with just a bit of ingenuity and effort, like actively taking the stairs or walking fast.
      `,
      down: `Your exercise score has gone down %d pts since February.<br>
      You can increase your activity level in your daily life with just a bit of ingenuity and effort, like actively taking the stairs or walking fast.
      `,
      equal: `Your exercise score is the same as February.<br>
      You can increase your activity level in your daily life with just a bit of ingenuity and effort, like actively taking the stairs or walking fast.
      `,
    },
    meal: {
      up: `Your food score has gone up %d pts since February.<br>
      Eating a good breakfast will help improve your concentration and memory. Make sure you get your carbohydrates and protein.
      `,
      down: `Your food score has gone down %d pts since February.<br>
      Eating a good breakfast will help improve your concentration and memory. Make sure you get your carbohydrates and protein.
      `,
      equal: `Your food score is the same as February.<br>
      Eating a good breakfast will help improve your concentration and memory. Make sure you get your carbohydrates and protein.
      `,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since February.<br>
      Alcohol has a diuretic affect, so drinking it leaves you prone to dehydration. 
      Make sure you drink water frequently.
      `,
      down: `Your alcohol score has gone down %d pts since February.<br>
      Alcohol has a diuretic affect, so drinking it leaves you prone to dehydration. 
      Make sure you drink water frequently.
      `,
      equal: `Your alcohol score is the same as February.<br>
      Alcohol has a diuretic affect, so drinking it leaves you prone to dehydration. 
      Make sure you drink water frequently.
      `,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since February. <br>
      Temperatures vary heavily in spring, so your sleep quality can easily deteriorate in this part of the year. 
      As soon as you wake up in the morning, get into the sunlight and reset your internal clock!
      `,
      down: `Your sleep score has gone down %d pts since February. <br>
      Temperatures vary heavily in spring, so your sleep quality can easily deteriorate in this part of the year. 
      As soon as you wake up in the morning, get into the sunlight and reset your internal clock!
      `,
      equal: `Your sleep score is the same as February. <br>
      Temperatures vary heavily in spring, so your sleep quality can easily deteriorate in this part of the year. 
      As soon as you wake up in the morning, get into the sunlight and reset your internal clock!
      `,
    },
    stress: {
      up: `Your stress score has gone up %d pts since February. <br>
      A new fiscal year brings about a lot of environmental changes from relocations, etc. 
      <a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">This video</a> is perfect for those who have trouble talking to others when first meeting!
      `,
      down: `Your stress score has gone down %d pts since February. <br>
      A new fiscal year brings about a lot of environmental changes from relocations, etc. 
      <a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">This video</a> is perfect for those who have trouble talking to others when first meeting!
      `,
      equal: `Your stress score is the same as February. <br>
      A new fiscal year brings about a lot of environmental changes from relocations, etc. 
      <a href="https://go.andwell.jp/library/9693bead-98ff-11ec-b342-06d158a16ffc">This video</a> is perfect for those who have trouble talking to others when first meeting!
      `,
    },
  },
}
